import React from 'react'
import styled from 'styled-components'
import ParseContent from 'components/shared/ParseContent'

// Elements
import ButtonDefault from 'components/elements/ButtonDefault'
import Blogik, {
  BlogConsumer,
  BlogButton,
  BlogFilter,
} from 'components/shared/Blogik'

// Components
import Image from 'components/shared/ImageGatsby'

// SVG
import ArrowRightWhite from 'img/arrow-right-white.svg'

const StyledImage = styled(Image)`
  @media (max-width: 767px) {
    max-height: 263px;
  }

  @media (min-width: 768px) {
    max-height: 357px;
  }

  @media (min-width: 1200px) {
    min-height: 488px;
    max-height: 488px;
  }
`
const ExcerptWrapper = styled.div`
  bottom: 100px;

  @media (max-width: 767px) {
    bottom: 0;
  }
`

const ExcerptContent = styled.div`
  min-height: 256px;
  background-color: ${(props) => props.theme.color.face.light};
  border: 1px solid ${(props) => props.theme.color.text.secondary};
`

const Excerpt = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    font-size: ${(props) => props.theme.font.size[16]};
    color: ${(props) => props.theme.color.text.dark};
    line-height: ${(props) => props.theme.font.size[20]};
  }

  p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;

    @media (min-width: 1200px) {
      -webkit-line-clamp: 3;
    }

    @media (max-width: 1199px) {
      -webkit-line-clamp: 4;
    }
  }
`

const StyledButtonDefault = styled(ButtonDefault)`
  min-width: 200px;
  max-width: 200px;

  & a {
    &:after {
      transition: all 0.2s ease-in-out;
      padding-left: 10px;
      content: url(${ArrowRightWhite});
    }
    &:hover:after {
      padding-left: 20px;
    }
  }

  @media (max-width: 991px) {
    max-width: 180px;
    min-width: 180px;
  }
`

const BlogWrapper = styled.div`
  @media (max-width: 767px) {
    border-bottom: 1px solid ${(props) => props.theme.color.text.secondary};
  }
`

const ProjectButtonWrapper = styled.div`
  @media (min-width: 767px) {
    height: 100%;
  }

  @media (max-width: 1199px) {
    margin-left: -30px;
    margin-top: -30px;
    position: relative;
    top: -30px;
  }
`

interface BlogProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Blog
  location: any
}

const Blog: React.FC<BlogProps> = ({ fields }) => (
  <section>
    <div className="container py-lg-5 my-5">
      <div className="row justify-content-center">
        <div className="col-lg-9 col-12 d-flex flex-column">
          <Blogik
            settings={{
              id: 'blog',
              limit: Number(fields.limit),
            }}
          >
            <div>
              <BlogFilters />
              <BlogGrid fields={fields} />
            </div>
          </Blogik>
        </div>
      </div>
    </div>
  </section>
)

const StyledBlogFilter = styled(BlogFilter) <{ selected: boolean }>`
  font-weight: ${(props) =>
    props.selected ? props.theme.font.weight.l : props.theme.font.weight.s};
`

interface BlogFiltersProps { }

interface BlogFilterProps {
  node: {
    id: string
    name: string
  }
}

const BlogFilters: React.FC<BlogFiltersProps> = () => (
  <BlogConsumer>
    {({ categories, isSelected }: any) => (
      <div className="container d-flex">
        {categories.map((category: BlogFilterProps) => (
          <StyledBlogFilter
            key={category.node.id}
            id={category.node.id}
            selected={isSelected(category.node.id)}
            className="mr-3 d-none"
          >
            {category.node.name}
          </StyledBlogFilter>
        ))}
      </div>
    )}
  </BlogConsumer>
)

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Blog
}

const BlogGrid: React.FC<BlogGridProps> = ({ fields }) => (
  <BlogConsumer>
    {({ posts, showMoreButton }: any) => (
      <div className="container">
        <div className="row">
          {posts.map((post: BlogGridPostProps) => (
            <div key={post.node.id} className="mb-4 mb-lg-0">
              <BlogGridPost node={post.node} blogFields={fields} />
            </div>
          ))}
        </div>

        {showMoreButton && (
          <div className="mt-5 text-center">
            <ButtonDefault to="/" isCustom>
              <BlogButton>{fields.loadMoreText}</BlogButton>
            </ButtonDefault>
          </div>
        )}
      </div>
    )}
  </BlogConsumer>
)

interface BlogGridPostProps {
  // eslint-disable-next-line
  blogFields: GatsbyTypes.WpPage_Flexcontent_Flex_Blog
  node: {
    id: string
    title: string
    uri: string
  }
}

const BlogGridPost: React.FC<BlogGridPostProps> = ({ node, blogFields }) => (
  <BlogWrapper>
    <StyledImage
      image={node.blogDetail.thumbnail}
      loading="lazy"
    />
    <div className="d-flex justify-content-center">
      <ExcerptWrapper className="row position-relative align-items-center justify-content-between mx-lg-5 mx-0">
        <ExcerptContent className="p-lg-5 p-4 col-md-10 col-12">
          {/* <div className="d-flex">
            {selectedCategories.map((edge: any, index: number) => (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className="pb-3 mr-4 d-flex align-items-center"
              >
                <img
                  src={
                    edge.node.projectCategory.icon.localFile.publicURL
                  }
                  className="mr-2"
                  alt="Lofthus icoon"
                />
                <span className="text-lowercase">{edge.node.name}</span>
              </div>
            ))}
          </div> */}

          <Excerpt content={node.blogDetail.excerpt} />
          <StyledButtonDefault to={node.uri} className="mt-4">
            lees meer
          </StyledButtonDefault>
        </ExcerptContent>
        {/* <ProjectButtonWrapper className="d-flex justify-content-xl-center justify-content-end align-items-end px-0 align-items-xl-center col-md-3 col-12 mb-5 mb-md-0 mt-md-5 mt-0 pl-xl-5">
          <StyledButtonDefault to={node.uri} className="">
            lees meer
          </StyledButtonDefault>
        </ProjectButtonWrapper> */}
      </ExcerptWrapper>
    </div>
  </BlogWrapper>
)

export default Blog
